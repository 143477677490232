import { createRouter, createWebHistory } from "vue-router";
import PhotosView from "../views/PhotosView.vue";
import PasswordView from "../views/PasswordView.vue";
import SignInView from "../views/AuthViews/SignInView.vue";
import SignUpView from "../views/AuthViews/SignUpView.vue";
import PersonView from "../views/PersonView.vue";
import AlbumView from "../views/AlbumView.vue";
import TapesView from "../views/TapesView.vue";
import CamcorderDetail from "../components/Camcorder/CamcorderDetail.vue";
import PrivacyView from "../views/PublicViews/PrivacyPage.vue";
import LoginMasterPage from "../views/LoginMasterPage.vue";

import { h } from "vue";
import { RouterView } from "vue-router";

const routes = [
  {
    path: "/photos",
    name: "photos-view-parent",
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        component: PhotosView,
        name: "PhotosView",
      },
      {
        path: "person/:person",
        name: "PersonView",
        component: PersonView,
      },
      {
        path: "album/:album",
        name: "AlbumView",
        component: AlbumView,
      },
    ],
  },
  {
    path: "/camcorder",
    name: "TapesView",
    component: TapesView,
    children: [
      {
        path: ":year/:day",
        component: CamcorderDetail,
        name: "CamcorderDetailView",
      },
    ],
  },
  {
    path: "/admin",
    name: "AdminView",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/AdminViews/AdminMaster.vue"
      ),
    children: [
      {
        path: "map-users",
        name: "MapUsers",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/AdminViews/MapUsers.vue"
          ),
      },
      {
        path: "missioncontrol",
        name: "MissionControl",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/AdminViews/MissionControl.vue"
          ),
      },
      {
        path: "upload",
        name: "UploadContent",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/AdminViews/UploadContent.vue"
          ),
      },
      {
        path: "premiers",
        name: "ManagePremiers",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/AdminViews/ManagePremiers.vue"
          ),
      },
      {
        path: "recent-logins",
        name: "RecentLogins",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/AdminViews/RecentLogins.vue"
          ),
      },
    ],
  },
  {
    path: "/auth",
    name: "LoginMasterPage-View",
    component: LoginMasterPage,
    children: [
      {
        path: "/auth/signin",
        name: "SignInView-view",
        component: SignInView,
      },
      {
        path: "/auth/signup",
        name: "SignUpView-view",
        component: SignUpView,
      },
      {
        path: "/auth",
        name: "password-view",
        component: PasswordView,
      },
    ],
  },

  {
    path: "/privacy",
    name: "Privacy-view",
    component: PrivacyView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to) => {
  const vuex = await import("@/store");
  if (
    to.name !== "password-view" &&
    to.name !== "SignInView-view" &&
    to.name !== "SignUpView-view" &&
    to.name !== "LoginMasterPage-View" &&
    to.name !== "Privacy-view"
  ) {
    if (vuex.store.state.token === "") {
      return "/auth";
    }
  }
});

export default router;
